
.bottom-slider{
    top: 50px;
}

.bottom-slider:hover,
.bottom-slider:active,
.bottom-slider .MuiSlider-thumb:hover,
.bottom-slider .MuiSlider-thumb:active,
.bottom-slider .MuiSlider-track:hover,
.bottom-slider .MuiSlider-track:active,
.bottom-slider .MuiSlider-rail:hover,
.bottom-slider .MuiSlider-rail:active {
  z-index: 30;
}
.slider2 .MuiSlider-rail  {
    color: transparent;
}
.MuiSlider-track,  .MuiSlider-thumb {
   color: rgb(30, 85, 107);
}
.MuiSlider-rail {
    color:grey
}
.slider-container {
    width: 100%;
    display:flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;

}


.slider-btn {
    margin-left: 3rem;
    background-color: white;
    border: black;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 2px;
   
    
}

.slider-btn:hover {
   border: 3px;
   border-style: solid;

}

.week-text{
    margin-right: 3rem;
}

@media screen and (max-width: 768px) {
    .bottom-slider{
        top: 64px;
    }
    
    

}