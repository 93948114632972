#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
 
}



.slider-div{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 
}

.btn-div {
 margin-top: 3rem;
}
.savebtn{
 background-color:orange;
 border-radius: 13px;
 border-color: rgb(125, 82, 3);
 padding-left: 1rem;
 padding-right: 1rem;
 padding-top: 0.5rem;
 padding-bottom: 0.5rem;
 font-size: 1.5rem;
 color: white;
 border: none;
}

.header{
  text-align: start;
  color: orange;
}